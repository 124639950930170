// Vue
import Vue from 'vue';
import Vuex from 'vuex';

// Vuex logger
import createLogger from 'vuex/dist/logger';

// App specific
import { accountStoreModule } from '../modules/account';
import {
  agendaStoreModule,
  agendaSearchesStoreModule,
} from '../modules/agenda';
import {
  documentStoreModule,
  documentPdfStoreModule,
  documentSearchesStoreModule,
  documentStatisticsStoreModule,
  documentTimelineStoreModule,
} from '../modules/document';
import {
  europarlDocumentStoreModule,
  europarlPdfStoreModule,
  europarlSearchesStoreModule,
  europarlStatisticsStoreModule,
  europarlTimelineStoreModule,
} from '../modules/europarl';
import {
  listStoreModule,
  listItemStoreModule,
} from '../modules/list';
import {
  localRegionalDocumentStoreModule,
  localRegionalPdfStoreModule,
  localRegionalSearchesStoreModule,
  localRegionalStatisticsStoreModule,
  localRegionalTimelineStoreModule,
} from '../modules/local-regional';
// import { documentFilterStoreModule } from '../modules/document-filter';

import authentication from './modules/authentication';
import autocomplete from './modules/autocomplete';
import browser from './modules/browser';
import busy from './modules/busy';
import confirmDialog from './modules/confirmDialog';
import messages from './modules/messages';
import referrer from './modules/referrer';
import searchParameters from './modules/searchParameters';
import visibility from './modules/visibility';
import emailUpdate from './modules/emailUpdate';

Vue.use(Vuex);

// const debug = process.env.NODE_ENV !== 'production';
const debug = false;

export default new Vuex.Store({
  modules: {
    accountStoreModule,
    agendaStoreModule,
    agendaSearchesStoreModule,
    documentStoreModule,
    documentPdfStoreModule,
    documentSearchesStoreModule,
    documentTimelineStoreModule,
    documentStatisticsStoreModule,
    europarlDocumentStoreModule,
    europarlPdfStoreModule,
    europarlSearchesStoreModule,
    europarlStatisticsStoreModule,
    europarlTimelineStoreModule,
    listStoreModule,
    listItemStoreModule,
    localRegionalDocumentStoreModule,
    localRegionalPdfStoreModule,
    localRegionalSearchesStoreModule,
    localRegionalStatisticsStoreModule,
    localRegionalTimelineStoreModule,
    // documentFilterStoreModule,
    autocomplete,
    authentication,
    busy,
    browser,
    confirmDialog,
    messages,
    referrer,
    searchParameters,
    visibility,
    emailUpdate,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
