// TODO: separation between EU and NL PDFs no longer exists in backend - should this split also be removed here?

// App specific
import pdfApi from '../api/europarl-api-pdf';

// initial state
const state = {
  europarlCheckPdf: false,
  euPdf: null,
  euPdfMissing: false,
};

// getters
const getters = {
  europarlCheckPdf: (state) => state.europarlCheckPdf,
  euPdf: (state) => state.euPdf,
  euPdfMissing: (state) => state.euPdfMissing,
};

// actions
const actions = {

  /**
   * Retrieves whether or not a PDF exists for a particular document from the backend API
   *
   * @param {any} { commit }
   * @param {String} args.docType - Document type of the document to check if a PDF exists for
   * @param {String} args.docId - ID of the document to check if a PDF exists for
   */
  async europarlCheckPdf({ commit }, args) {
    await pdfApi.checkPdf(args.docType, args.docId)
      .then((response) => {
        if (response.data.access === 'Granted') {
          commit('europarlSetCheckPdf', true);
        } else {
          commit('europarlSetCheckPdf', false);
        }
      });
  },

  /**
   * Retrieves the PDF data of a particular document from the backend API
   *
   * @param {any} { commit }
   * @param {String} args.docType - Document type of the document to get PDF data for
   * @param {String} args.docId - ID of the document to get PDF data for
   */
  async euGetPdf({ commit }, args) {
    await pdfApi.getPdf(args.docType, args.docId)
      .then((response) => {
        if (response.data.byteLength > 140) {
          commit('europarlSetPdf', response.data);
        } else {
          commit('europarlSetPdfMissing');
        }
      });
  },

  europarlSetPdf: ({ commit, state }, newValue) => {
    commit('europarlSetPdf', newValue);
    return state.euPdf;
  },

  europarlSetCheckPdf: ({ commit, state }, newValue) => {
    commit('europarlSetCheckPdf', newValue);
    return state.europarlCheckPdf;
  },

};

// mutations
const mutations = {
  europarlSetCheckPdf(state, checkPdf) {
    state.europarlCheckPdf = checkPdf;
  },

  europarlSetPdf(state, pdf) {
    state.euPdf = pdf;
  },

  europarlSetPdfMissing(state) {
    state.euPdfMissing = true;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
