import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import { accountRoutes } from '../modules/account';
import { ifAuthenticated } from './routing_helpers';
import { documentRoutes } from '../modules/document';
import { europarlRoutes } from '../modules/europarl';
import { agendaRoutes } from '../modules/agenda';
import { listRoutes } from '../modules/list';
import { localRegionalRoutes } from '../modules/local-regional';
// Lets make this rout impossible to reach for the moment
// TODO: Have to remove this after backend fix for filters has been esthablished
// import { documentFilterRoutes } from '../modules/document-filter'

// https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const baseRoutes = [
  {
    path: '/',
    redirect: { name: 'dashboard' },
  },
  {
    path: '/:env(eu|nl-lr)?/document/:documentId/:highlightWord?',
    name: 'document',
    beforeEnter: ifAuthenticated,
    // Lazy loading document
    component: () => import(/* webpackChunkName: "DocumentDetail" */ '../views/DocumentDetail.vue'),
  },
];

const routes = [
  ...baseRoutes,
  ...documentRoutes,
  ...europarlRoutes,
  ...agendaRoutes,
  ...accountRoutes,
  ...listRoutes,
  ...localRegionalRoutes,
  // Temporary disabled due to mockup data see imports above
  // ...documentFilterRoutes,
  { path: '*', redirect: '/' },
];

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    return {};
  },
  routes,
});

// We need to weed out all routes that are not authenticated. All
// routes that are not authenticated will be redirected to login :)
// When already logged in routes directed to 'login' will be redirected to dashboard.
router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !store.getters.authIsAuthenticated) next({ name: 'login' });
  else if (to.name !== 'login' && !store.getters.authIsAuthenticated) next({ name: 'login' });
  // Guard to make sure that nothing is shown to users that don't have any active modules
  else if (to.name !== 'login' && store.getters.authActiveModules.length === 0) next({ name: 'login' });
  /*
    This is a dirty hack to make the local/regional independent working for
    certain customers. We abuse the 'MC11' module for this. In the future
    we might to do this more structured.
    If a user has no access rights to both the nl- and the local/regional dashboard
    and visits the '/dashboard' the user should be redirected to the login page.
    If we are dealing with users that have access rights to the local/regional
    dashboard and no access to the nl-dashboard we route '/' and '/dashboard'
    to /nl-lr/dashboard.
  */
  else if (to.name === 'dashboard'
    && (!store.getters.authHasModuleAccess('MC11')
    && !store.getters.authHasModuleAccess('MC13'))) next({ name: 'login' });
  else if (to.name === 'dashboard'
    && (!store.getters.authHasModuleAccess('MC11')
    && store.getters.authHasModuleAccess('MC13'))) next('/nl-lr/dashboard');
  else next();
});

export default router;
