/*
 * Store to manage the state of stored search queries
 */

import Config from '@/config';
import documentSearchesApi from '../../../api/document/document-api-searches';

// initial state
const state = {
  // Holds all searches made by the user, this is the "search history"
  allRecentSearches: [],

  // Holds all searches saved by the user on the server
  allSavedSearches: [],

  maxNumberOfSearches: null,

  // This is the currently selected search, could be recent or saved
  // Is used to highlight the active search in the list (via its id)
  currentSearch: {
    id: null,
    title: null,
    description: null,
    sendUpdatesPerEmail: {
      daily: false,
      realTime: false,
    },
    queryDetails: {
      context: [],
      groupPaths: [],
      groupingDetails: {
        name: null,
        version: null,
      },
      minimalMatchPercentage: 0,
      query: null,
    },
  },
};

// getters
const getters = {
  allRecentSearches: (state) => state.allRecentSearches,
  allSavedSearches: (state) => state.allSavedSearches,
  currentSearch: (state) => state.currentSearch,
  maxNumberOfSearches: (state) => state.maxNumberOfSearches,
};

// actions
const actions = {
  // Next line is absolutly necessary
  // eslint-disable-next-line no-unused-vars
  async getAllSavedSearches({ state, commit, rootState }, payload) {
    // TODO: Add 'shortDomainType' derived from router name 'nl' or 'eu'
    const response = await documentSearchesApi.getAll({ groupingName: 'nlFiltersGrouping' });
    commit('setSavedSearches', response.data);
    commit('maxNumberOfSearches', response.data.numberOfSearches);
    return response;
  },

  // Next line is absolutly necessary
  // eslint-disable-next-line no-unused-vars
  async saveSearch({ commit, state }, search) {
    let response;
    // If an id is provided the search is already stored on the server
    if (search.id) {
      response = await documentSearchesApi.update(search);
    } else {
      response = await documentSearchesApi.save(search);
    }

    // Refresh the saved searches list with the data on the server to make sure everything between
    // server and client is in sync
    this.dispatch('getAllSavedSearches');
    return response;
  },

  // Next line is absolutly necessary
  // eslint-disable-next-line no-unused-vars
  async saveSearches({ commit, state }, search) {
    // Update multiple searches at once
    let response;
    try {
      response = await documentSearchesApi.updates(search);
    } finally {
      // Refresh the saved searches list with the data on the server to make sure everything between
      // server and client is in sync
      this.dispatch('getAllSavedSearches');
    }

    return response;
  },

  // search is the current selected saved search object
  async updateSearch({ state, rootState, commit }, search) {
    // We are going to update the current active saved search with the current searchparameters
    // This is only allowed in a mutation, therefore we use syncCurrentSearch
    commit('syncCurrentSearch', { search, searchParameters: rootState.searchParameters });

    // Store the merged currentSearch on the server
    const response = await documentSearchesApi.update(state.currentSearch);

    this.dispatch('getAllSavedSearches');
    return response;
  },

  // Next line is absolutly necessary
  // eslint-disable-next-line no-unused-vars
  async removeSearch({ commit, state }, search) {
    const response = await documentSearchesApi.delete(search);
    this.dispatch('getAllSavedSearches');
    return response;
  },

  async setCurrentSearch({ commit }, search) {
    commit('setCurrentSearch', search);
  },

  resetCurrentSearch({ commit }) {
    commit('resetCurrentSearch');
  },

  async setRecentSearches({ commit }, searches) {
    commit('setRecentSearches', searches);
  },
};

// mutations
const mutations = {
  setCurrentSearch(state, search) {
    state.currentSearch = search;
  },
  setSavedSearches(state, searches) {
    // Reverse order of array because we want to draw the latest item on top.
    searches.data.reverse();
    state.allSavedSearches = searches;
  },
  addSavedSearch(state, search) {
    state.allSavedSearches.data.unshift(search);
  },
  setRecentSearches(state, searches) {
    state.allRecentSearches = searches;
  },
  maxNumberOfSearches(state, payload) {
    state.maxNumberOfSearches = payload;
  },
  addRecentSearch(state, search) {
    // Context and document types are sorted to avoid duplicates
    // when we are creating hashes in the future to detect if a search is
    // really new\
    const recentSearch = search;
    const sortedQueryDetails = {
      query: recentSearch.queryDetails.query,
      context: recentSearch.queryDetails.context.sort(),
      groupPaths: recentSearch.queryDetails.groupPaths.sort(),
      groupingDetails: recentSearch.queryDetails.groupingDetails,
      minimalMatchPercentage: recentSearch.queryDetails.minimalMatchPercentage,
    };

    recentSearch.queryDetails = sortedQueryDetails;

    // Add to the top
    state.allRecentSearches.unshift(recentSearch);

    if (state.allRecentSearches.length >= Config.maxRecentSearches) {
      // Remove last item
      state.allRecentSearches.splice(-1, 1);
    }
  },
  syncCurrentSearch(state, { searchParameters }) {
    state.currentSearch.queryDetails = searchParameters;
    state.currentSearch.queryDetails.query = searchParameters.query;
  },
  resetCurrentSearch(state) {
    state.currentSearch = {
      title: null,
      description: null,
      sendUpdatesPerEmail: {
        daily: false,
        realTime: false,
      },
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
