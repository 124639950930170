/*
 * Store to manage all search parameters
 */

import Config from '@/config';

// Returns an ISO date string with today minus the configurated offset
function datePickerOffset() {
  const dateMinusOffset = new Date();
  dateMinusOffset.setHours(0, 0, 0, 0);
  dateMinusOffset.setTime(dateMinusOffset.getTime() - Config.dateFilterOffset);
  return dateMinusOffset.toISOString();
}

// initial state
const state = {
  query: '',
  startDate: datePickerOffset(),
  endDate: (new Date()).toISOString(),
  context: [],
  groupingDetails: {
    name: null,
    version: null,
  },
  groupPaths: [],
  euEpCommittees: [],

  minimalMatchPercentage: parseInt(localStorage.getItem('minimalMatchPercentage'), 10) || Config.defaultMinimalMatchPercentage,
  size: Config.pageSizeTimeline,
  agendaFilters: [],
  lookBack: false,
};

// getters
const getters = {
  allSearchParameters: (state) => state,
  searchParameterQuery: (state) => state.query,
  searchParameterGroupPaths: (state) => state.groupPaths,
  searchParameterEuEpCommittees: (state) => state.euEpCommittees,
  searchParameterDateRange: (state) => ([state.startDate, state.endDate]),
  searchParameterMinimalMatchPercentage: (state) => state.minimalMatchPercentage,
  lookBack: (state) => state.lookBack,
};

// actions
const actions = {

  setLookBack({ commit }, boolean) {
    commit('setLookBack', boolean);
  },

  async setAllSearchParameters({ commit, dispatch }, payload) {
    await commit('setAllSearchParameters', payload);
    await dispatch('getAllTimelineItems', true);
  },

  async europarlSetAllSearchParameters({ commit, dispatch }, payload) {
    await commit('europarlSetAllSearchParameters', payload);
    await dispatch('europarlGetAllTimelineItems', true);
  },

  async localRegionalSetAllSearchParameters({ commit, dispatch }, payload) {
    await commit('localRegionalSetAllSearchParameters', payload);
    await dispatch('localRegionalGetAllTimelineItems', true);
  },

  setQuery({ commit }, payload) {
    commit('updateQuery', payload);
  },

  setDateRange({ commit }, payload) {
    commit('updateDateRange', payload);
  },

  setContext({ commit }, payload) {
    commit('updateContext', payload);
  },

  setGroupPaths({ commit }, payload) {
    commit('updateGroupPaths', payload);
  },

  setGroupingDetails({ commit }, payload) {
    commit('updateGroupingDetails', payload);
  },

  setEuEpCommittees({ commit }, payload) {
    commit('updateEuEpCommitteess', payload);
  },

  setMinimalMatchPercentage({ commit }, payload) {
    commit('updateMinimalMatchPercentage', payload);
  },

  setCheckboxFilter({ commit }, args) {
    commit('setCheckboxFilter', args);
  },
};

// mutations
const mutations = {

  setLookBack(state, boolean) {
    state.lookBack = boolean;
  },

  async setAllSearchParameters(state, searchParameters) {
    this.commit('updateQuery', searchParameters.query);
    this.commit('updateContext', searchParameters.context);
    this.commit('updateGroupPaths', searchParameters.groupPaths);
    this.commit('updateDateRange', searchParameters.dateRange);
    this.commit('updateMinimalMatchPercentage', searchParameters.minimalMatchPercentage);
  },

  async europarlSetAllSearchParameters(state, searchParameters) {
    this.commit('updateQuery', searchParameters.query);
    this.commit('updateGroupPaths', searchParameters.groupPaths);
    this.commit('updateEuEpCommitteess', searchParameters.euEpCommittees);
    this.commit('updateDateRange', searchParameters.dateRange);
  },

  async localRegionalSetAllSearchParameters(state, searchParameters) {
    this.commit('updateQuery', searchParameters.query);
    this.commit('updateGroupPaths', searchParameters.groupPaths);
    this.commit('updateDateRange', searchParameters.dateRange);
  },

  updateQuery(state, query) {
    state.query = query;
  },

  updateDateRange(state, dateRange) {
    if (dateRange) {
      state.startDate = dateRange.startDate;
      state.endDate = dateRange.endDate;
    }
  },

  updateContext(state, context) {
    state.context = context;
  },

  updateGroupPaths(state, groupPaths) {
    state.groupPaths = groupPaths;
  },

  updateEuEpCommitteess(state, commissions) {
    state.euEpCommittees = commissions;
  },

  updateGroupingDetails(state, args) {
    state.groupingDetails = args;
  },

  updateMinimalMatchPercentage(state, percentage) {
    state.minimalMatchPercentage = percentage;
    localStorage.setItem('minimalMatchPercentage', percentage);
  },

  setCheckboxFilter(state, args) {
    state[args.filterKey] = args.filterValue;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
