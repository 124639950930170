// App specific
import Config from '@/config';
import statisticsAPI from '../../../api/document/document-api-statistics';

// initial state
const state = {
  localRegionalStatisticsTrend: null,
  localRegionalStatisticsStakeholders: [],
  localRegionalStatisticsTrendingTopics: [],
};

// getters
const getters = {
  localRegionalStatisticsTrend: (state) => state.localRegionalStatisticsTrend,
  localRegionalStatisticsStakeholders: (state) => state.localRegionalStatisticsStakeholders,
  localRegionalStatisticsTrendingTopics: (state) => state.localRegionalStatisticsTrendingTopics,
};

// actions
const actions = {

  async localRegionalGetStatistics({ commit, rootState }, analyticsCode) {
    let code = analyticsCode;
    // Get the currently set searchparameters
    const { searchParameters } = rootState;

    // Add statictics grouping to use
    searchParameters.trendsGroupingName = 'nlLrTrendsGrouping';

    const responseTrend = await statisticsAPI.getTrend(searchParameters);
    commit('localRegionalSetTrend', responseTrend.data);

    if (code) {
      localStorage.setItem('nlLrAnalyticsCode', code);
    } else {
      code = localStorage.getItem('nlLrAnalyticsCode') || Config.nlLrDefaultAnalyticsCode;
    }

    const { data: { data: stakeholdersData } } = await statisticsAPI
      .getStakeholders(searchParameters, code);

    commit('localRegionalSetStakeholders', stakeholdersData);

    const responseTrendingTopics = await statisticsAPI.getTrendingTopics(searchParameters);

    commit('localRegionalSetTrendingTopics', responseTrendingTopics.data.data);
  },
};

// mutations
const mutations = {
  localRegionalSetTrend(state, trend) {
    state.localRegionalStatisticsTrend = trend;
  },
  localRegionalSetStakeholders(state, stakeholders) {
    state.localRegionalStatisticsStakeholders = stakeholders;
  },
  localRegionalSetTrendingTopics(state, trendingTopics) {
    state.localRegionalStatisticsTrendingTopics = trendingTopics;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
