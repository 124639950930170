/*
 * Store to manage the state of stored search queries
 */

import Config from '@/config';
import europarlSearchesApi from '../api/europarl-api-searches';

// initial state
const state = {
  // Holds all searches made by the user, this is the "search history"
  europarlAllRecentSearches: [],

  // Holds all searches saved by the user on the server
  europarlAllSavedSearches: [],

  europarlMaxNumberOfSearches: null,

  // This is the currently selected search, could be recent or saved
  // Is used to highlight the active search in the list (via its id)
  europarlCurrentSearch: {
    id: null,
    title: null,
    description: null,
    sendUpdatesPerEmail: {
      daily: false,
      realTime: false,
    },
    queryDetails: {
      groupPaths: [],
      groupingDetails: {
        name: null,
        version: null,
      },
      euEpCommittees: [],
      query: null,
    },
  },
};

// getters
const getters = {
  europarlAllRecentSearches: (state) => state.europarlAllRecentSearches,
  europarlAllSavedSearches: (state) => state.europarlAllSavedSearches,
  europarlCurrentSearch: (state) => state.europarlCurrentSearch,
  europarlMaxNumberOfSearches: (state) => state.europarlMaxNumberOfSearches,
};

// actions
const actions = {
  // Next line is absolutly necessary
  // eslint-disable-next-line no-unused-vars
  async europarlGetAllSavedSearches({ state, commit, rootState }, payload) {
    const response = await europarlSearchesApi.getAll();
    commit('europarlSetSavedSearches', response.data);
    commit('europarlMaxNumberOfSearches', response.data.numberOfSearches);
    return response;
  },

  // Next line is absolutly necessary
  // eslint-disable-next-line no-unused-vars
  async europarlSaveSearch({ commit, state }, search) {
    let response;
    // If an id is provided the search is already stored on the server
    if (search.id) {
      response = await europarlSearchesApi.update(search);
    } else {
      response = await europarlSearchesApi.save(search);
    }

    // Refresh the saved searches list with the data on the server to make sure everything between
    // server and client is in sync
    this.dispatch('europarlGetAllSavedSearches');
    return response;
  },

  // Next line is absolutly necessary
  // eslint-disable-next-line no-unused-vars
  async europarlSaveSearches({ commit, state }, search) {
    // Update multiple searches at once
    let response;
    try {
      response = await europarlSearchesApi.updates(search);
    } finally {
      // Refresh the saved searches list with the data on the server to make sure everything between
      // server and client is in sync
      this.dispatch('europarlGetAllSavedSearches');
    }

    return response;
  },

  // search is the current selected saved search object
  async europarlUpdateSearch({ state, rootState, commit }, search) {
    // We are going to update the current active saved search with the current searchparameters
    // This is only allowed in a mutation, therefore we use syncCurrentSearch
    commit('europarlSyncCurrentSearch', { search, searchParameters: rootState.searchParameters });

    // Store the merged currentSearch on the server
    const response = await europarlSearchesApi.update(state.europarlCurrentSearch);

    this.dispatch('europarlGetAllSavedSearches');
    return response;
  },

  // Next line is absolutly necessary
  // eslint-disable-next-line no-unused-vars
  async europarlRemoveSearch({ commit, state }, search) {
    const response = await europarlSearchesApi.delete(search);
    this.dispatch('europarlGetAllSavedSearches');
    return response;
  },

  async europarlSetCurrentSearch({ commit }, search) {
    commit('europarlSetCurrentSearch', search);
  },

  europarlResetCurrentSearch({ commit }) {
    commit('europarlResetCurrentSearch');
  },
};

// mutations
const mutations = {
  europarlSetCurrentSearch(state, search) {
    state.europarlCurrentSearch = search;
  },
  europarlSetSavedSearches(state, searches) {
    // Reverse order of array because we want to draw the latest item on top.
    searches.data.reverse();
    state.europarlAllSavedSearches = searches;
  },
  europarlAddSavedSearch(state, search) {
    state.europarlAllSavedSearches.data.unshift(search);
  },
  europarlSetRecentSearches(state, searches) {
    state.europarlAllRecentSearches = searches;
  },
  europarlMaxNumberOfSearches(state, payload) {
    state.europarlMaxNumberOfSearches = payload;
  },
  europarlAddRecentSearch(state, search) {
    // Context and document types are sorted to avoid duplicates
    // when we are creating hashes in the future to detect if a search is
    // really new\
    const recentSearch = search;

    // Add to the top
    state.europarlAllRecentSearches.unshift(recentSearch);

    if (state.europarlAllRecentSearches.length >= Config.maxRecentSearches) {
      // Remove last item
      state.europarlAllRecentSearches.splice(-1, 1);
    }
  },
  europarlSyncCurrentSearch(state, { searchParameters }) {
    state.europarlCurrentSearch.queryDetails = searchParameters;
    state.europarlCurrentSearch.queryDetails.query = searchParameters.query;
  },
  europarlResetCurrentSearch(state) {
    state.europarlCurrentSearch = {
      title: null,
      description: null,
      sendUpdatesPerEmail: {
        daily: false,
        realTime: false,
      },
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
