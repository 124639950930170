// TODO: separation between EU and NL PDFs no longer exists in backend - should this split also be removed here?

// App specific
import pdfApi from '../../../api/document/document-api-pdf';

// initial state
const state = {
  checkPdf: false,
  nlPdf: null,
  nlPdfMissing: false,
};

// getters
const getters = {
  checkPdf: (state) => state.checkPdf,
  nlPdf: (state) => state.nlPdf,
  nlPdfMissing: (state) => state.nlPdfMissing,
};

// actions
const actions = {

  /**
   * Retrieves whether or not a PDF exists for a particular document from the backend API
   *
   * @param {any} { commit }
   * @param {String} args.docType - Document type of the document to check if a PDF exists for
   * @param {String} args.docId - ID of the document to check if a PDF exists for
   */
  async checkPdf({ commit }, args) {
    await pdfApi.checkPdf(args.docType, args.docId)
      .then((response) => {
        if (response.data.access === 'Granted') {
          commit('setCheckPdf', true);
        } else {
          commit('setCheckPdf', false);
        }
      });
  },

  /**
   * Retrieves the PDF data of a particular document from the backend API
   *
   * @param {any} { commit }
   * @param {String} args.docType - Document type of the document to get PDF data for
   * @param {String} args.docId - ID of the document to get PDF data for
   */
  async nlGetPdf({ commit }, args) {
    await pdfApi.getPdf(args.docType, args.docId)
      .then((response) => {
        if (response.data.byteLength > 140) {
          commit('setPdf', response.data);
        } else {
          commit('setPdfMissing');
        }
      });
  },

  setPdf: ({ commit, state }, newValue) => {
    commit('setPdf', newValue);
    return state.nlPdf;
  },

  setCheckPdf: ({ commit, state }, newValue) => {
    commit('setCheckPdf', newValue);
    return state.checkPdf;
  },

};

// mutations
const mutations = {
  setCheckPdf(state, checkPdf) {
    state.checkPdf = checkPdf;
  },

  setPdf(state, pdf) {
    state.nlPdf = pdf;
  },

  setPdfMissing(state) {
    state.nlPdfMissing = true;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
