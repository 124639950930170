/*
 * Store to manage the visibility of items
 */

const state = {
  sidebarVisible: {
    left: false,
    right: false,
  },
  documentDialogFormSavedSearches: false,
  europarlDialogFormSavedSearches: false,
  localRegionalDialogFormSavedSearches: false,
  agendaDialogFormSavedSearches: false,
  dialogConfirmDialog: false,
  supportDialog: false,
  trendAnalyticsExpanded: false,
};

// getters
const getters = {
  sidebarVisible: (state) => state.sidebarVisible,
  dialogDocumentFormSavedSearchesVisible: (state) => state.documentDialogFormSavedSearches,
  dialogEuroparlFormSavedSearchesVisible: (state) => state.europarlDialogFormSavedSearches,
  dialogLocalRegionalFormSavedSearchesVisible:
    (state) => state.localRegionalDialogFormSavedSearches,
  dialogAgendaFormSavedSearchesVisible: (state) => state.agendaDialogFormSavedSearches,
  dialogConfirmDialogVisible: (state) => state.dialogConfirmDialog,
  supportDialogVisible: (state) => state.supportDialog,
  trendAnalyticsExpanded: (state) => state.trendAnalyticsExpanded,
};

// actions
const actions = {

  // Sidebars
  hideSidebars({ commit }) {
    commit('setSidebarVisible', { sidebar: 'right', visible: false });
    commit('setSidebarVisible', { sidebar: 'left', visible: false });
  },
  setSidebarVisible({ commit }, args) {
    commit('setSidebarVisible', args);
  },
  toggleSidebarVisible({ commit }, sidebar) {
    commit('toggleSidebarVisible', sidebar);
  },

  // Forms
  // toggleDialogFormSavedSearches({ commit }) {
  //   commit('toggleDialogFormSavedSearchesVisible');
  // },
  setDocumentDialogFormSavedSearchesVisible({ commit }, visible) {
    commit('setDocumentDialogFormSavedSearchesVisible', visible);
  },
  setEuroparlDialogFormSavedSearchesVisible({ commit }, visible) {
    commit('setEuroparlDialogFormSavedSearchesVisible', visible);
  },
  setLocalRegionalDialogFormSavedSearchesVisible({ commit }, visible) {
    commit('setLocalRegionalDialogFormSavedSearchesVisible', visible);
  },
  setAgendaDialogFormSavedSearchesVisible({ commit }, visible) {
    commit('setAgendaDialogFormSavedSearchesVisible', visible);
  },

  // ConfirmDialog
  toggleConfirmDialog({ commit }) {
    commit('toggleConfirmDialogVisible');
  },
  setConfirmDialogVisible({ commit }, visible) {
    commit('setConfirmDialogVisible', visible);
  },

  // SupportDialog
  toggleSupportDialog({ commit }) {
    commit('toggleSupportDialogVisible');
  },
  setSupportDialogVisible({ commit }, visible) {
    commit('setSupportDialogVisible', visible);
  },

  // trendAnalyticsExpanded
  toggleTrendAnalyticsExpanded({ commit }) {
    commit('toggleTrendAnalyticsExpanded');
  },

};

// mutations
const mutations = {
  toggleSidebarVisible(state, sidebar) {
    state.sidebarVisible[sidebar] = !state.sidebarVisible[sidebar];
  },
  setSidebarVisible(state, args) {
    state.sidebarVisible[args.sidebar] = args.visible;
  },

  // toggleDialogFormSavedSearches(state) {
  //   state.dialogFormSavedSearches = !state.dialogFormSavedSearches;
  // },
  setDocumentDialogFormSavedSearchesVisible(state, visible) {
    state.documentDialogFormSavedSearches = visible;
  },
  setEuroparlDialogFormSavedSearchesVisible(state, visible) {
    state.europarlDialogFormSavedSearches = visible;
  },
  setLocalRegionalDialogFormSavedSearchesVisible(state, visible) {
    state.localRegionalDialogFormSavedSearches = visible;
  },
  setAgendaDialogFormSavedSearchesVisible(state, visible) {
    state.agendaDialogFormSavedSearches = visible;
  },

  toggleConfirmDialog(state) {
    state.dialogConfirmDialog = !state.dialogConfirmDialog;
  },
  setConfirmDialogVisible(state, visible) {
    state.dialogConfirmDialog = visible;
  },

  toggleSupportDialog(state) {
    state.supportDialog = !state.supportDialog;
  },
  setSupportDialogVisible(state, visible) {
    state.supportDialog = visible;
  },
  toggleTrendAnalyticsExpanded(state) {
    state.trendAnalyticsExpanded = !state.trendAnalyticsExpanded;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
