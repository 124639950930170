import accountApi from '../api/account-api';

// initial state
const defaultState = {
  userFilters: {},
  userPreferences: {},
  userEmailPreferences: {},
  userEmailTemplateOptions: {},
  organizationPreferences: {},
};

const state = defaultState;

// getters
const getters = {
  userFilters: (state) => state.userFilters,
  userPreferences: (state) => state.userPreferences,
  userEmailPreferences: (state) => state.userEmailPreferences,
  userEmailTemplateOptions: (state) => state.userEmailTemplateOptions,
  organizationPreferences: (state) => state.organizationPreferences,
};

// actions
const actions = {

  async getPreferences({ commit }) {
    const { data } = await accountApi.getPreferences();
    commit('setUserPreferences', data.user);
    commit('setOrganizationPreferences', data.organization);

    const { data: emailData } = await accountApi.getEmailPreferences();
    commit('setUserEmailPreferences', emailData.emailConfig);
    commit('setUserEmailTemplateOptions', emailData.templateOptions);

    return data;
  },

  async setPreferences({ commit }, preferences) {
    const { data } = await accountApi.updatePreferences(preferences);
    commit('setUserPreferences', data.user);
    commit('setOrganizationPreferences', data.organization);
    return data;
  },

  async setEmailPreferences({ commit }, preferences) {
    const { data } = await accountApi.updateEmailPreferences(preferences);
    commit('setUserEmailPreferences', data.emailConfig);
    return data;
  },

  async getFilterTypesForUser({ commit }) {
    const response = await accountApi.getNlGroupingForUser();
    const {
      contexts,
      grouping,
      groupsByDocType,
      documentTypesConfig,
    } = response.data;

    const filters = {
      context: contexts,
      grouping,
      groupsByDocType,
      documentTypesConfig,
    };

    commit('updateFilterTypesForUser', filters);
    return filters;
  },

  async getEuroparlFilterTypesForUser({ commit }) {
    const response = await accountApi.getEuGroupingForUser();
    const {
      euGrouping,
      euGroupsByDocType,
      euEpCommittees,
    } = response.data;

    const filters = {
      euGrouping,
      euGroupsByDocType,
      euEpCommittees,
    };

    commit('updateFilterTypesForUser', filters);
    return filters;
  },

  async getNlLrFilterTypesForUser({ commit }) {
    const response = await accountApi.getNlLrGroupingForUser();
    const {
      nlLrGrouping,
      nlLrGroupsByDocType,
    } = response.data;

    const filters = {
      nlLrGrouping,
      nlLrGroupsByDocType,
    };

    commit('updateFilterTypesForUser', filters);
    return filters;
  },

  async getAgendaFilterTypesForUser({ commit }) {
    const response = await accountApi.getAgendaFiltersForUser();
    const filters = { agenda: response.data };
    commit('updateFilterTypesForUser', filters);
    return filters;
  },

  async getStakeholderSourceList() {
    return accountApi.getStakeholderSourceList();
  },

  async sendWebCalInviteMail() {
    const response = await accountApi.sendWebCalInviteMail();
    return response.data;
  },
};

// mutations
const mutations = {
  setUserPreferences(state, preferences) {
    // Save theme setting in localStorage
    localStorage.setItem('uiDarkMode', JSON.stringify(preferences.uiDarkMode));
    state.userPreferences = preferences;
  },
  setUserEmailPreferences(state, preferences) {
    state.userEmailPreferences = preferences;
  },
  setUserEmailTemplateOptions(state, templateOptions) {
    state.userEmailTemplateOptions = templateOptions;
  },
  setOrganizationPreferences(state, preferences) {
    state.organizationPreferences = preferences;
  },
  updateFilterTypesForUser(state, filters) {
    state.userFilters = {
      ...state.userFilters,
      ...filters,
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
