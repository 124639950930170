/*
 * This file holds all server specific configurable settings
 */

export default {
  // Server address without slash on end
  // address: 'http://localhost:3010/api',
  address: process.env.VUE_APP_HOST,
  admin_address: process.env.VUE_APP_ADMIN,
};
