import polpoApi from '@/api/polpoApi';
import store from '@/store';

export default {
  async getTimelineItems(searchParameters) {
    const {
      query,
      context,
      groupingDetails: { name: groupingName },
      contextRequired,
    } = searchParameters;

    if (query.trim() === '') {
      // eslint-disable-next-line no-console
      console.error('Invalid empty query! Not sending request to nl/timeline!');
      return null;
    }
    if (!groupingName) {
      // eslint-disable-next-line no-console
      console.error('Invalid missing grouping name! Not sending request to nl/timeline!');
      return null;
    }
    // Check for missing / invalid context, but only on searches with NL (not NL-LR) grouping
    if (contextRequired && (!context || context.length === 0)) {
      // eslint-disable-next-line no-console
      console.error(`Invalid empty contexts! Not sending request to nl/timeline! Grouping: ${groupingName}`);
      // Sometimes it occures that leftover timeline items will be drawn when no contexts are selected.
      // This should never happen! Therefore we wipe all timeline items
      store.dispatch('clearTimelineItems');
      return null;
    }
    return polpoApi.post('nl/timeline', searchParameters);
  },
};
