import { loginAuthenticate } from '../../router/routing_helpers';

export default [
  {
    path: '/document-filter-module',
    name: 'document-filter',
    beforeEnter: loginAuthenticate,
    component: () => import(/* webpackChunkName: "AgendaOverview" */ './views/DocumentFilterOverview.vue'),
  },
];
