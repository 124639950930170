import polpoApi from '@/api/polpoApi';

export default {
  getTrend: async (searchParameters) => polpoApi.post('nl/statistics/trends', searchParameters),
  getTrendingTopics: async (searchParameters) => polpoApi.post('nl/statistics/trending-topics', searchParameters),
  getStakeholders: async (
    searchParameters,
    stakeholderCode,
  ) => polpoApi.post('nl/statistics/stakeholders', {
    ...searchParameters,
    stakeholderCode,
  }),
  getFrequencies: async (searchParameters) => polpoApi.post('nl/statistics/frequencies', searchParameters),
};
