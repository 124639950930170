import { loginAuthenticate } from '../../router/routing_helpers';
// import DocumentOverview from './views/DocumentOverview.vue'

export default [
  {
    path: '/eu/dashboard',
    name: 'eu-dashboard',
    beforeEnter: loginAuthenticate,
    component: () => import(/* webpackChunkName: "EuroparlOverview" */ './views/EuroparlOverview.vue'),
  },
];
