import polpoApi from '@/api/polpoApi';

export default {

  getById: async (id, query, origin) => {
    const args = {};
    if (query) { args.query = query; }
    if (origin) { args.origin = origin; }
    return polpoApi.getById('agendas', id, args);
  },

  // TODO: remove when the backend can parse query from body in post requests
  getAll: async (query, filters, startDate, endDate, origin) => polpoApi.post(`agendas?query=${encodeURIComponent(query)}${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}${origin ? `&origin=${origin}` : ''}`, {
    sessionFilters: filters,
  }),

};
