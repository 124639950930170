import { loginAuthenticate } from '../../router/routing_helpers';
// import DocumentOverview from './views/DocumentOverview.vue'

export default [
  {
    path: '/nl-lr/dashboard',
    name: 'local-regional-dashboard',
    beforeEnter: loginAuthenticate,
    component: () => import(/* webpackChunkName: "LocalRegionalOverview" */ './views/LocalRegionalOverview.vue'),
  },
];
