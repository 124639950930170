import polpoApi from '@/api/polpoApi';

export default {
  getTrend: async (
    searchParameters,
  ) => polpoApi.post('eu/statistics/trends', searchParameters),
  getStakeholders: async (
    searchParameters,
    analyticsCode,
  ) => polpoApi.post('eu/statistics/stakeholders', {
    ...searchParameters,
    ...{
      analyticsType: analyticsCode,
    },
  }),
};
