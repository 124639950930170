import Vue from 'vue';
import ServerConfig from '@/config.server';
// Without this unused Interceptors import there will be no data. So this eslint-disable is 100% necessary
// eslint-disable-next-line no-unused-vars
// import Interceptors from './interceptors';

/**
 * Class to manage api requests to the Polpo API endpoint
 *
 * @class PolpoApi
 */

const apiUri = ServerConfig.address;

class PolpoApi {
  /**
   * Makes a GET request to the server
   *
   * @param {any} controller Controller to handle the request
   * @param {any} parameters Parameters as object
   * @returns Promise of the API call
   * @memberof PolpoApi
   */
  async get(controller, parameters) {
    const queryString = new URLSearchParams(parameters).toString();
    const request = {
      method: 'GET',
      url: `${apiUri}/${controller}/?${queryString}`,
    };
    return this.makeRequest(request);
  }

  /**
   * Makes a POST request to the server
   *
   * @param {any} controller Controller to handle the request
   * @param {any} data Post request data as object
   * @returns Promise of the API call
   * @memberof PolpoApi
   */
  async post(controller, data) {
    const request = {
      method: 'POST',
      url: `${apiUri}/${controller}`,
      data,
    };
    return this.makeRequest(request);
  }

  /**
   * Makes a POST request to the server
   *
   * @param {any} controller Controller to handle the request
   * @param {any} data Post request data as object
   * @returns Promise of the API call
   * @memberof PolpoApi
   */
  // https://stackoverflow.com/questions/73550410/download-excel-file-created-in-node-using-sheetjs-xlxs-nodejs-react
  async postExcel(controller, data) {
    const request = {
      method: 'POST',
      responseType: 'arraybuffer',
      url: `${apiUri}/${controller}`,
      data,
    };
    return this.makeRequest(request);
  }

  /**
   * Makes a PUT request to the server
   *
   * @param {any} controller Controller to handle the request
   * @param {any} data Put request data as object
   * @returns Promise of the API call
   * @memberof PolpoApi
   */
  async put(controller, data) {
    const request = {
      method: 'PUT',
      url: `${apiUri}/${controller}/${data.id}`,
      data,
    };
    return this.makeRequest(request);
  }

  /**
   * Makes a PUT request to the server without setting id of object
   *
   * @param {any} controller Controller to handle the request
   * @param {any} data Put request data as object
   * @returns Promise of the API call
   * @memberof PolpoApi
   */
  async putRaw(controller, data) {
    const request = {
      method: 'PUT',
      url: `${apiUri}/${controller}`,
      data,
    };
    return this.makeRequest(request);
  }

  /**
   * Makes a PATCH request to the server without setting id of object
   *
   * @param {any} controller Controller to handle the request
   * @param {any} data Put request data as object
   * @returns Promise of the API call
   * @memberof PolpoApi
   */
  async patchRaw(controller, data) {
    const request = {
      method: 'PATCH',
      url: `${apiUri}/${controller}`,
      data,
    };
    return this.makeRequest(request);
  }

  /**
   * Makes a DELETE request to the server
   *
   * @param {any} controller Controller to handle the request
   * @param {any} id Id of entity to delete
   * @returns Promise of the API call
   * @memberof PolpoApi
   */
  async delete(controller, data) {
    const request = {
      method: 'DELETE',
      url: `${apiUri}/${controller}/${data.id}`,
      data,
    };
    return this.makeRequest(request);
  }

  /**
   * Makes a GET request for an entitiy to the url specified
   *
   * @param {any} url Url to Polpo endpoint
   * @returns Promise of the API call
   * @memberof PolpoApi
   */
  async getEntityByUrl(url) {
    const request = {
      method: 'GET',
      url,
      timeout: 10000,
    };
    return this.makeRequest(request);
  }

  /**
   * Makes a GET request to the server to a controller and entity id
   *
   * @param {any} controller Controller to handle the request
   * @param {any} id Entity id
   * @param {any} [parameters] Object with querystring parameters
   * @returns Promise of the API call
   * @memberof PolpoApi
   */
  async getById(controller, id, parameters) {
    let url = `${apiUri}/${controller}/${id}`;

    if (parameters) {
      const queryString = new URLSearchParams(parameters).toString();
      url += `?${queryString}`;
    }

    const request = {
      method: 'GET',
      url,
    };
    return this.makeRequest(request);
  }

  /**
   * Makes a POST request to the server to a controller and entity id
   *
   * @param {any} controller Controller to handle the request
   * @param {any} id Entity id
   * @param {any} [data] Object with body data
   * @returns Promise of the API call
   * @memberof PolpoApi
   */
  async postById(controller, id, data) {
    const url = `${apiUri}/${controller}/${id}`;

    const request = {
      method: 'POST',
      url,
      data,
    };
    return this.makeRequest(request);
  }


  /**
   * Makes a GET request to the server to get metadata of a file / PDF exists for a specific document.
   *
   * @param {String} route - The route on the backend to send the request to
   * @param {String} docType - Type of the document to get metadata of a file / PDF for
   * @param {String} docId - (Internal) document ID of the document to get metadata of a file / PDF for
   * @param {any} [parameters] - Object with querystring parameters
   * @returns Promise of the API call's response
   * @memberof PolpoApi
   */
  async getFileMetadata(route, docType, docId, parameters) {
    let url = `${apiUri}/${route}/${encodeURIComponent(docType)}/${docId}`;

    // Parameters can be used to specify a MIME type. If not specified, the backend defaults to PDF.
    // Example for .doc files: { mimeType: 'application/msword' }.
    if (parameters) {
      const queryString = new URLSearchParams(parameters).toString();
      url += `?${queryString}`;
    }

    const request = {
      method: 'GET',
      url,
    };
    return this.makeRequest(request);
  }

  /**
   * Makes a GET request to the server to retrieve a file / PDF for a specific document.
   *
   * @param {String} route - The route on the backend to send the request to
   * @param {String} docType - Type of the document to retrieve a file / PDF for
   * @param {String} docId - (Internal) document ID of the document to retrieve a file / PDF for
   * @param {any} [parameters] - Object with querystring parameters
   * @returns Promise of the API call's response
   * @memberof PolpoApi
   */
  async getFileData(route, docType, docId, parameters) {
    let url = `${apiUri}/${route}/${encodeURIComponent(docType)}/${docId}`;

    // Parameters can be used to specify a MIME type. If not specified, the backend defaults to PDF.
    // Example for .doc files: { mimeType: 'application/msword' }.
    if (parameters) {
      const queryString = new URLSearchParams(parameters).toString();
      url += `?${queryString}`;
    }

    const request = {
      method: 'GET',
      url,
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      timeout: 30000,
    };
    return this.makeRequest(request);
  }

  /**
   * Handles all requests to the server
   *
   * @param {any} request Axios request object
   * @returns Promise of Axios call
   * @memberof PolpoApi
   */
  async makeRequest(request) {
    // request.timeout = Config.requestTimeout;
    this.request = request;
    try {
      return await Vue.axios(this.request);
    } catch (error) {
      throw error.response;
    }
  }
}

export default new PolpoApi();
