// App specific
import pdfApi from '../../../api/document/document-api-pdf';

// initial state
const state = {
  nlLrCheckPdf: false,
  nlLrPdf: null,
  nlLrPdfMissing: false,
};

// getters
const getters = {
  nlLrCheckPdf: (state) => state.nlLrCheckPdf,
  nlLrPdf: (state) => state.nlLrPdf,
  nlLrPdfMissing: (state) => state.nlLrPdfMissing,
};

// actions
const actions = {

  /**
   * Retreives document by its api endpoint url
   *
   * @param {any} { commit }
   */
  async nlLrCheckPdf({ commit }, args) {
    await pdfApi.checkPdf(args.docType, args.docId)
      .then((response) => {
        if (response.data.access === 'Granted') {
          commit('nlLrSetCheckPdf', true);
        } else {
          commit('nlLrSetCheckPdf', false);
        }
      });
  },

  /**
   * Retreives document by its api endpoint url
   *
   * @param {any} { commit }
   */
  async nlLrGetPdf({ commit }, args) {
    await pdfApi.getPdf(args.docType, args.docId)
      .then((response) => {
        if (response.data.byteLength > 140) {
          commit('nlLrSetPdf', response.data);
        } else {
          commit('nlLrSetPdfMissing');
        }
      });
  },

  nlLrSetPdf: ({ commit, state }, newValue) => {
    commit('nlLrSetPdf', newValue);
    return state.nlLrPdf;
  },

  nlLrSetCheckPdf: ({ commit, state }, newValue) => {
    commit('nlLrSetCheckPdf', newValue);
    return state.nlLrCheckPdf;
  },

};

// mutations
const mutations = {
  nlLrSetCheckPdf(state, checkPdf) {
    state.nlLrCheckPdf = checkPdf;
  },

  nlLrSetPdf(state, pdf) {
    state.nlLrPdf = pdf;
  },

  nlLrSetPdfMissing(state) {
    state.nlLrPdfMissing = true;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
