// App specific
import listItemApi from '../api/list-api-item';

// initial state
const state = {

};

// getters
const getters = {

};

// actions
const actions = {

  async saveListItem({ dispatch }, listItem) {
    let response;

    if (listItem.id) {
      response = await listItemApi.update(listItem);
      dispatch('getListItems', listItem.listId);
    } else {
      response = await listItemApi.save(listItem);
    }
    return response;
  },
  async removeListItem({ dispatch }, listItem) {
    await listItemApi.delete(listItem);
    dispatch('getListItems', listItem.listId);
  },
  async moveListItem({ dispatch, getters }, options) {
    const { index, direction } = options;
    const { listCurrentItems: currentListItems } = getters;

    const listItemA = currentListItems[index];
    const listItemB = currentListItems[index + direction];

    await listItemApi.swapPosition(listItemA.id, listItemB.id);

    dispatch('getListItems', listItemA.listId);
  },
  async toggleListItemMarkedForExport({ dispatch }, listItem) {
    const listItemCopy = { ...listItem };
    listItemCopy.markedForExport = !listItemCopy.markedForExport;

    const updatedListItem = await listItemApi.update(listItemCopy);
    dispatch('getListItems', listItemCopy.listId);
    return updatedListItem;
  },
};

// mutations
const mutations = {

};

export default {
  state,
  getters,
  actions,
  mutations,
};
