// App specific
import listApi from '../api/list-api';

// initial state
const state = {
  listsActive: null,
  listsArchived: null,
  currentList: null,
  formVisible: false,
  currentListItems: [],
};

// getters
const getters = {
  listsArchived: (state) => state.listsArchived,
  listsActive: (state) => state.listsActive,
  listCurrent: (state) => state.currentList,
  listCurrentItems: (state) => state.currentListItems,
};

// actions
const actions = {

  async getList({ commit, dispatch }, id) {
    const list = await listApi.get(id);
    commit('setCurrentList', list.data);
    dispatch('getListItems', id);
    return list;
  },

  async getListExportHTMLPreviewToken({ getters }) {
    const { listCurrent: currentList } = getters;
    const result = await listApi.getListExportHTMLPreviewToken(currentList.id);
    return result.data;
  },

  async getListItems({ commit }, listId) {
    const listItems = await listApi.getListItems(listId);
    commit('setCurrentListItems', listItems.data);
    return listItems;
  },
  async removeCurrentList({ dispatch, getters }) {
    const { listCurrent: currentList } = getters;

    await listApi.delete(currentList);

    if (currentList.archived) {
      dispatch('getArchivedLists');
    } else {
      dispatch('getActiveLists');
    }
  },

  async getArchivedLists({ commit, dispatch }, selectFirst = true) {
    const lists = await listApi.getArchived();
    commit('setListsArchived', lists.data);
    if (selectFirst && lists.data.length > 0) {
      commit('setCurrentList', lists.data[0]);
      dispatch('getListItems', lists.data[0].id);
    }
    return lists;
  },

  async getActiveLists({ commit, dispatch }, selectFirst = true) {
    const lists = await listApi.getActive();
    commit('setListsActive', lists.data);
    if (selectFirst && lists.data.length > 0) {
      commit('setCurrentList', lists.data[0]);
      dispatch('getListItems', lists.data[0].id);
    }
    return lists;
  },

  async saveList({ commit, dispatch }, list) {
    let response;

    if (list.id) {
      response = await listApi.update(list);
      if (list.archived) {
        dispatch('getArchivedLists', false);
      } else {
        dispatch('getActiveLists', false);
      }
    } else {
      response = await listApi.save(list);
      commit('addNewList', response.data);
    }
    commit('setCurrentList', response.data);

    return response;
  },

  async toggleListArchive({ dispatch, getters, commit }) {
    const { listCurrent: currentList } = getters;

    const listCurrentCopy = { ...currentList };
    listCurrentCopy.archived = !listCurrentCopy.archived;

    const updatedList = await listApi.update(listCurrentCopy);
    dispatch('getActiveLists', false);
    dispatch('getArchivedLists', false);
    commit('setCurrentList', listCurrentCopy);
    return updatedList;
  },

  async verifyTransferEmail(_, email) {
    return listApi.verify(email);
  },

  async transferLists({ dispatch }, { email, listIds, successString }) {
    const transferResult = await listApi.transfer(email, listIds);

    // When successful, show the results
    const { status, data: transferredLists } = transferResult;
    const { config: { data: requestData } } = transferResult;
    const { email: targetEmail } = JSON.parse(requestData);
    const amountTransferred = transferredLists ? transferredLists.length : null;

    if (status === 200 && amountTransferred && targetEmail) {
      dispatch('setMessage', {
        message: `${amountTransferred} ${successString} ${targetEmail}`,
        type: 'success',
      });
    }

    dispatch('getActiveLists');
    return transferResult;
  },
};

// mutations
const mutations = {
  setListsArchived(state, lists) {
    state.listsArchived = lists;
  },
  setListsActive(state, lists) {
    state.listsActive = lists;
  },
  setCurrentList(state, lists) {
    state.currentList = lists;
  },
  setCurrentListItems(state, listsItems) {
    state.currentListItems = listsItems;
  },
  addNewList(state, list) {
    state.listsActive = [list, ...state.listsActive];
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
