import { loginAuthenticate } from '../../router/routing_helpers';
// import DocumentOverview from './views/DocumentOverview.vue'

export default [
  {
    path: '/nl/dashboard',
    redirect: { name: 'dashboard' },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    beforeEnter: loginAuthenticate,
    component: () => import(/* webpackChunkName: "DocumentOverview" */ './views/DocumentOverview.vue'),
  },
];
