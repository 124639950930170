// initial state
const state = {
  filters: [],
};

// getters
const getters = {
  documentFilters: (state) => state.filters,
};

// actions
const actions = {
  setDocumentFilters({ commit }, args) {
    commit('setDocumentFilters', args);
  },
};

// mutations
const mutations = {
  setDocumentFilters(state, args) {
    state.filters = args;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
