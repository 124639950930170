/*
 * Store to manage the state of stored search queries
 */

import Config from '@/config';
import searchesApi from '../../../api/document/document-api-searches';

// initial state
const state = {
  // Holds all searches made by the user, this is the "search history"
  localRegionalAllRecentSearches: [],

  // Holds all searches saved by the user on the server
  localRegionalAllSavedSearches: [],

  localRegionalMaxNumberOfSearches: null,

  // This is the currently selected search, could be recent or saved
  // Is used to highlight the active search in the list (via its id)
  localRegionalCurrentSearch: {
    id: null,
    title: null,
    description: null,
    sendUpdatesPerEmail: {
      daily: false,
      realTime: false,
    },
    queryDetails: {
      groupPaths: [],
      groupingDetails: {
        name: null,
        version: null,
      },
      committees: [],
      query: null,
    },
  },
};

// getters
const getters = {
  localRegionalAllRecentSearches: (state) => state.localRegionalAllRecentSearches,
  localRegionalAllSavedSearches: (state) => state.localRegionalAllSavedSearches,
  localRegionalCurrentSearch: (state) => state.localRegionalCurrentSearch,
  localRegionalMaxNumberOfSearches: (state) => state.localRegionalMaxNumberOfSearches,
};

// actions
const actions = {
  // Next line is absolutly necessary
  // eslint-disable-next-line no-unused-vars
  async localRegionalGetAllSavedSearches({ state, commit, rootState }, payload) {
    const response = await searchesApi.getAll({ groupingName: 'nlLrFiltersGrouping' });
    commit('localRegionalSetSavedSearches', response.data);
    commit('localRegionalMaxNumberOfSearches', response.data.numberOfSearches);
    return response;
  },

  // Next line is absolutly necessary
  // eslint-disable-next-line no-unused-vars
  async localRegionalSaveSearch({ commit, state }, search) {
    let response;
    // If an id is provided the search is already stored on the server
    if (search.id) {
      response = await searchesApi.update(search);
    } else {
      response = await searchesApi.save(search);
    }

    // Refresh the saved searches list with the data on the server to make sure everything between
    // server and client is in sync
    this.dispatch('localRegionalGetAllSavedSearches');
    return response;
  },

  // Next line is absolutly necessary
  // eslint-disable-next-line no-unused-vars
  async localRegionalSaveSearches({ commit, state }, search) {
    // Update multiple searches at once
    let response;
    try {
      response = await searchesApi.updates(search);
    } finally {
      // Refresh the saved searches list with the data on the server to make sure everything between
      // server and client is in sync
      this.dispatch('localRegionalGetAllSavedSearches');
    }

    return response;
  },

  // search is the current selected saved search object
  async localRegionalUpdateSearch({ state, rootState, commit }, search) {
    // We are going to update the current active saved search with the current searchparameters
    // This is only allowed in a mutation, therefore we use syncCurrentSearch
    commit('localRegionalSyncCurrentSearch', { search, searchParameters: rootState.searchParameters });

    // Store the merged currentSearch on the server
    const response = await searchesApi.update(state.localRegionalCurrentSearch);

    this.dispatch('localRegionalGetAllSavedSearches');
    return response;
  },

  // Next line is absolutly necessary
  // eslint-disable-next-line no-unused-vars
  async localRegionalRemoveSearch({ commit, state }, search) {
    const response = await searchesApi.delete(search);
    this.dispatch('localRegionalGetAllSavedSearches');
    return response;
  },

  async localRegionalSetCurrentSearch({ commit }, search) {
    commit('localRegionalSetCurrentSearch', search);
  },

  localRegionalResetCurrentSearch({ commit }) {
    commit('localRegionalResetCurrentSearch');
  },
};

// mutations
const mutations = {
  localRegionalSetCurrentSearch(state, search) {
    state.localRegionalCurrentSearch = search;
  },
  localRegionalSetSavedSearches(state, searches) {
    // Reverse order of array because we want to draw the latest item on top.
    searches.data.reverse();
    state.localRegionalAllSavedSearches = searches;
  },
  localRegionalAddSavedSearch(state, search) {
    state.localRegionalAllSavedSearches.data.unshift(search);
  },
  localRegionalSetRecentSearches(state, searches) {
    state.localRegionalAllRecentSearches = searches;
  },
  localRegionalMaxNumberOfSearches(state, payload) {
    state.localRegionalMaxNumberOfSearches = payload;
  },
  localRegionalAddRecentSearch(state, search) {
    // Context and document types are sorted to avoid duplicates
    // when we are creating hashes in the future to detect if a search is
    // really new\
    const recentSearch = search;

    // Add to the top
    state.localRegionalAllRecentSearches.unshift(recentSearch);

    if (state.localRegionalAllRecentSearches.length >= Config.maxRecentSearches) {
      // Remove last item
      state.localRegionalAllRecentSearches.splice(-1, 1);
    }
  },
  localRegionalSyncCurrentSearch(state, { searchParameters }) {
    state.localRegionalCurrentSearch.queryDetails = searchParameters;
    state.localRegionalCurrentSearch.queryDetails.query = searchParameters.query;
  },
  localRegionalResetCurrentSearch(state) {
    state.localRegionalCurrentSearch = {
      title: null,
      description: null,
      sendUpdatesPerEmail: {
        daily: false,
        realTime: false,
      },
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
