import Vue from 'vue';
import VueI18n from 'vue-i18n';

import sharedTranslations from '../modules/shared';
import { accountTranslations } from '../modules/account';
import { agendaTranslations } from '../modules/agenda';
import { listTranslations } from '../modules/list';
import { localRegionalTranslations } from '../modules/local-regional';
import { documentTranslations } from '../modules/document';
import { europarlTranslations } from '../modules/europarl';
import { documentFilterTranslations } from '../modules/document-filter';

const languages = {
  en: {
    ...sharedTranslations.en,
    ...accountTranslations.en,
    ...agendaTranslations.en,
    ...listTranslations.en,
    ...localRegionalTranslations.en,
    ...documentTranslations.en,
    ...europarlTranslations.en,
    ...documentFilterTranslations.en,
  },
  nl: {
    ...sharedTranslations.nl,
    ...accountTranslations.nl,
    ...agendaTranslations.nl,
    ...listTranslations.nl,
    ...localRegionalTranslations.nl,
    ...documentTranslations.nl,
    ...europarlTranslations.nl,
    ...documentFilterTranslations.nl,
  },
};

const messages = { ...languages };

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'nl',
  fallbackLocale: 'nl',
  messages,
});
