import Config from '@/config';
import router from '@/router';
import timelineAPI from '../../../api/document/document-api-timeline';
// import localRegionalTimelineMock from './local-regional-timeline-mock';

// initial state
const state = {
  localRegionalSearchMoreAfter: null,

  localRegionalTimelineData: {
    data: [],
    countTotal: 0,
    hasMore: false,
  },
  localRegionalMaxDateRangeTimelineItemsTotal: 0,
};

// getters
const getters = {
  localRegionalSearchMoreAfter: (state) => state.localRegionalSearchMoreAfter,
  localRegionalTimelineItems: (state) => state.localRegionalTimelineData.data,
  localRegionalTimelineItemsCountTotal: (state) => state.localRegionalTimelineData.countTotal,
  localRegionalTimelineItemsHasMore: (state) => state.localRegionalSearchMoreAfter
    && (state.localRegionalTimelineData.data.length < state.localRegionalTimelineData.countTotal),
  localRegionalMaxDateRangeTimelineItemsHasMore: (state) => state.localRegionalNextItemUrl
    && (state.localRegionalTimelineData.data.length < state
      .localRegionalMaxDateRangeTimelineItemsTotal),
  localRegionalMaxDateRangeTimelineItemsTotal: (state) => state
    .localRegionalMaxDateRangeTimelineItemsTotal,
};

// actions
const actions = {
  localRegionalSetMaxDateRangeTimelineItemsTotal({ commit }, total) {
    commit('localRegionalSetMaxDateRangeTimelineItemsTotal', total);
  },

  localRegionalSetSearchMoreAfter({ commit }, url) {
    commit('localRegionalSetSearchMoreAfter', url);
  },

  // Although getEntityByUrl is asynchronous, it needs to be fired synchronous since the
  // items are sorted by time.
  async localRegionalGetAllTimelineItemsNext({ state, commit, rootState }) {
    // Check if localRegionalSearchMoreAfter is available
    if (state.localRegionalSearchMoreAfter) {
      const { searchParameters } = rootState;
      const response = await timelineAPI.getTimelineItems(
        { ...searchParameters, searchAfter: state.localRegionalSearchMoreAfter },
      );
      if (!response) {
        commit('setMessage', {
          message: 'Er is iets mis gegaan. Als dit probleem zich blijft voordoen neem dan contact met ons op.',
          type: 'info',
        });
        return;
      }
      if (response.data.countTotal > 0 && response.data.searchMoreAfter) {
        commit('localRegionalSetSearchMoreAfter', response.data.searchMoreAfter);
        commit('localRegionalAppendTimelineItems', response.data);
      }
    }
  },

  async localRegionalGetAllTimelineItems({ commit, rootState }, skipHistory) {
    // Lets see how many times we have been called
    const { searchParameters } = rootState;
    const {
      query,
      groupPaths,
      groupingDetails,
      committees,
    } = searchParameters;

    commit('localRegionalClearTimelineItems');

    // Reset all errors
    commit('clearMessages');

    // Set lookBack to false to avoid button will be drawn when switching to
    // other date range
    this.dispatch('setLookBack', false);

    // Validate a query has been set.
    if (!query) {
      commit('setMessage', {
        message: 'Geef een onderwerp op',
        type: 'info',
      }, { root: true });

      return;
    }

    // Validate a query has been set.
    if (query.length < Config.minimalQueryLength) {
      commit('setMessage', {
        message: `Onderwerp moet uit minimaal ${Config.minimalQueryLength} karakters bestaan`,
        type: 'info',
      }, { root: true });

      return;
    }

    // Remove any errors shown
    commit('clearMessages');

    // Mockupdata disable
    if (router.currentRoute.name === 'local-regional-dashboard' && !skipHistory) {
      // Add query to recent searches
      commit('localRegionalAddRecentSearch', {
        queryDetails: {
          query,
          groupPaths,
          groupingDetails,
          committees,
        },
      }, { root: true });
      // Set current search to null so none of the saved searches are shown as the active search
      commit('localRegionalResetCurrentSearch');
    }

    const response = await timelineAPI.getTimelineItems(
      { ...searchParameters, includeFullHistoryCount: true },
    );
    if (!response) {
      commit('setMessage', {
        message: 'Er is iets mis gegaan. Als dit probleem zich blijft voordoen neem dan contact met ons op.',
        type: 'info',
      });
      return;
    }

    commit('localRegionalSetTimelineItems', response.data);

    if (response.data.searchMoreAfter) {
      commit('localRegionalSetSearchMoreAfter', response.data.searchMoreAfter);
    }
    if (response.data.fullHistoryCount) {
      commit('localRegionalSetMaxDateRangeTimelineItemsTotal', response.data.fullHistoryCount);
    }

    // Fetch trend, stakeholders and trending topics
    this.dispatch('localRegionalGetStatistics');

    if (response.data.countTotal === 0) {
      this.dispatch('setLookBack', true);
      commit('setMessage', {
        message: 'Geen resultaten gevonden',
        type: 'info',
      });
    }
  },

  localRegionalClearTimelineItems({ commit }) {
    commit('localRegionalClearTimelineItems');
  },
};

// mutations
const mutations = {
  localRegionalSetMaxDateRangeTimelineItemsTotal(state, total) {
    state.localRegionalMaxDateRangeTimelineItemsTotal = total;
  },

  localRegionalSetSearchMoreAfter(state, localRegionalSearchMoreAfter) {
    state.localRegionalSearchMoreAfter = localRegionalSearchMoreAfter;
  },

  localRegionalSetTimelineItems(state, timelineData) {
    state.localRegionalTimelineData = timelineData;
    state.localRegionalTimelineData
      .hasMore = !!((timelineData.actions && timelineData.actions.more));
  },

  localRegionalAppendTimelineItems(state, timelineData) {
    state.localRegionalTimelineData.data = [...state
      .localRegionalTimelineData.data, ...timelineData.data];
  },

  localRegionalClearTimelineItems(state) {
    state.localRegionalTimelineData = {
      data: [],
      countTotal: 0,
      hasMore: false,
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
