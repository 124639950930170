<template>
  <v-container
    fluid
    :class="$vuetify.breakpoint.lgAndUp ? 'login-form__container' : ''"
    class="login-form__container fill-height d-flex justify-center align-center"
  >
    <v-card
      :width="$vuetify.breakpoint.smAndDown ? '100vw' : ''"
    >
      <v-container>
        <v-row>
          <v-col>
            <div class="login-form__header">
              <img src="../../../assets/polpo-logo.png">
              <!-- <div class="logo-shine animation--shine"></div> -->
            </div>
          </v-col>
        </v-row>

        <message-bar />

        <v-form
          ref="loginForm"
          v-model="valid"
          lazy-validation
          @keyup.enter.native="validate"
        >
          <v-text-field
            v-model="email"
            :label="$t('account.emailAddress')"
            :rules="emailRules"
            required
            outlined
          />

          <v-text-field
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            v-model="password"
            :label="$t('account.password')"
            :type="show ? 'text' : 'password'"
            :rules="passwordRules"
            required
            outlined
            @click:append="show = !show"
          />

          <div class="text-center">
            <v-btn
              color="teal"
              height="48"
              width="200"
              :disabled="!valid"
              @click="validate"
            >
              Login
            </v-btn>
          </div>
        </v-form>

        <div style="padding: 20px; text-align: center;">
          <button
            style="border: none; background: none; padding-right: 20px; text-decoration: underline; cursor: pointer; color: #5A738E;"
            @click="passwordRecoveryUrl()"
          >
            {{ $t('account.forgotPassword') }}
          </button>
          <a href="mailto:info@polpo.nl">{{ $t('account.supportInformation') }}</a>
        </div>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import MessageBar from '@/components/TheMessageBar.vue';
import ConfigServer from '@/config.server';

export default {
  name: 'Login',

  components: {
    MessageBar,
  },

  data() {
    return {
      show: false,
      busy: false,
      valid: false,
      email: '',
      emailRules: [
        (v) => !!v || this.$t('account.emailMandatory'),
        (v) => /.+@.+\..+/.test(v) || this.$t('account.enterValidEmail'),
      ],
      password: '',
      passwordRules: [
        (v) => !!v || this.$t('account.passwordMandatory'),
      ],
      params: this.$route.params,
    };
  },

  computed: {
    isAuthenticated: {
      get() {
        const isAuthenticated = this.$store.getters.authentication.authIsAuthenticated;
        if (isAuthenticated) {
          this.onIsAuthenticated();
        }
        this.$store.dispatch('getFilterTypesForUser');
        return isAuthenticated;
      },
    },
  },

  methods: {
    ...mapActions(['login']),

    onIsAuthenticated() {
      this.$router.push({ name: 'dashboard' });
    },

    passwordRecoveryUrl() {
      this.$gtag.event('account-password-recovery', {
        event_category: 'account',
        event_callback: this.gotoPasswordRecoveryUrl,
      });
      window.location.href = `${ConfigServer.admin_address}/wachtwoord-herstel`;
    },

    validate() {
      if (this.$refs.loginForm.validate()) {
        this.busy = true;
        const formdata = {
          email: this.email,
          password: this.password,
        };
        this.login(formdata).then((result) => {
          this.busy = true;
          const status = {
            status: 401,
            data: {
              token: '',
            },
          };

          const res = result || status;
          if (res.status === 200 && res.data.token) {
            const setStuff = async () => {
              await this.$store.dispatch('getFilterTypesForUser');
              if (this.$route.params.redirectTo) {
                await this.$router.push({ name: this.$route.params.redirectTo });
              } else {
                // Next line is absolutly neccessary
                // eslint-disable-next-line no-underscore-dangle
                const prevRoute = this.$router.history._startLocation;
                if (prevRoute && prevRoute !== '/login') {
                  await this.$router.push({ path: prevRoute });
                } else {
                  await this.$router.push({ path: '/dashboard' });
                }
              }
            };
            setStuff();
          }
        }, () => {
          this.busy = false;
        });
      }
    },
  },

  mounted() {
    this.$gtag.pageview({
      page_path: '/login',
    });
  },

};
</script>

<style scoped lang="scss">
  a:link {
    text-decoration: underline;
  }

  .login-form__header {
    position: relative;
    // background: white;
    padding: 15px 0;

    img {
      display: block;
      margin: 0 auto;
    }

    .logo-shine {
      height: 85px;
      width: 200px;
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -100px;
    }
  }

  .animation--shine {
    width: 200px;

    animation-name: ShineAnimation;
    animation-duration: 7s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.12, 0.89, 0.98, 0.47);
  }

  @keyframes ShineAnimation {
    from {
      background-repeat: no-repeat;
      background-image: linear-gradient(
        to left,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 45%,
        rgba(255, 255, 255, 0.5) 48%,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(255, 255, 255, 0.5) 52%,
        rgba(255, 255, 255, 0) 57%,
        rgba(255, 255, 255, 0) 100%
      );
      background-position: -400px -250px;
      background-size: 600px 600px;
    }
    to {
      background-repeat: no-repeat;
      background-position: 250px 250px;
    }
  }

  .login-form__container {
    background-image: url('../../../assets/background-login-top.png'), url('../../../assets/background-login-bottom.png');
    background-position: top, bottom;
    background-size: 100vw 50vh, contain;
    background-repeat: no-repeat, no-repeat;
    background-color: white;
  }

</style>
