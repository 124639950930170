import polpoApi from '@/api/polpoApi';

export default {
  async getTimelineItems(searchParameters) {
    if (searchParameters.query.trim() === '') {
      // eslint-disable-next-line no-console
      console.error('Invalid empty query! Not sending request to eu/timeline!');
      return null;
    }
    return polpoApi.post('eu/timeline', searchParameters);
  },
};
