/*
 * Store to manage the state of stored search queries
 */

// App specific
import Config from '@/config';
import agendaSearchesApi from '../api/agenda-api-searches';

// initial state
const state = {
  // Holds all searches made by the user, this is the "search history"
  agendaAllRecentSearches: [],

  // Holds all searches saved by the user on the server
  agendaAllSavedSearches: [],

  agendaMaxNumberOfSearches: null,

  // This is the currently selected search, could be recent or saved
  // Is used to highlight the active search in the list (via its id)
  agendaCurrentSearch: {
    id: null,
    title: null,
    includeInWebcal: false,
    sendUpdatesPerEmail: {
      dailyChanges: false,
    },
    queryDetails: {
      filters: [],
      query: null,
    },
  },
};

// getters
const getters = {
  agendaAllRecentSearches: (state) => state.agendaAllRecentSearches,
  agendaAllSavedSearches: (state) => state.agendaAllSavedSearches,
  agendaCurrentSearch: (state) => state.agendaCurrentSearch,
  agendaMaxNumberOfSearches: (state) => state.agendaMaxNumberOfSearches,
};

// actions
const actions = {
  // Next line is absolutely necessary
  // eslint-disable-next-line no-unused-vars
  async agendaGetAllSavedSearches({ state, commit, rootState }, payload) {
    const response = await agendaSearchesApi.getAll();
    commit('agendaSetSavedSearches', response.data);
    commit('agendaMaxNumberOfSearches', response.data.numberOfSearches);
    return response;
  },

  // Next line is absolutely necessary
  // eslint-disable-next-line no-unused-vars
  async agendaSaveSearch({ commit, state }, search) {
    let response;
    // If an id is provided the search is already stored on the server
    if (search.id) {
      response = await agendaSearchesApi.update(search);
    } else {
      response = await agendaSearchesApi.save(search);
    }

    // Entree point to check whether an 'Agenda-koppeling' activation mail
    // has been send.
    if (response.data.webcalInvite === true) {
      this.dispatch('setMessage', {
        message: 'Een activatiemail is verstuurd, activeer de Polpo kalender via de link in de e-mail',
        type: 'info',
      });
    }

    // Refresh the saved searches list with the data on the server to make sure everything between
    // server and client is in sync
    this.dispatch('agendaGetAllSavedSearches');
    return response;
  },

  // Next line is absolutly necessary
  // eslint-disable-next-line no-unused-vars
  async agendaSaveSearches({ commit, state }, search) {
    // Update multiple searches at once
    let response;
    try {
      response = await agendaSearchesApi.updates(search);
    } finally {
      // Refresh the saved searches list with the data on the server to make sure everything between
      // server and client is in sync
      this.dispatch('agendaGetAllSavedSearches');
    }

    return response;
  },

  // search is the current selected saved search object
  async agendaUpdateSearch({ state, rootState, commit }, search) {
    // We are going to update the current active saved search with the current searchparameters
    // This is only allowed in a mutation, therefore we use syncCurrentSearch
    commit('agendaSyncCurrentSearch', {
      search,
      searchParameters: {
        filters: rootState.agendaStoreModule.filters,
        query: rootState.agendaStoreModule.query,
      },
    });

    // Store the merged currentSearch on the server
    const response = await agendaSearchesApi.update(state.agendaCurrentSearch);

    this.dispatch('agendaGetAllSavedSearches');
    return response;
  },

  // Next line is absolutely necessary
  // eslint-disable-next-line no-unused-vars
  async agendaRemoveSearch({ commit, state }, search) {
    const response = await agendaSearchesApi.delete(search);
    this.dispatch('agendaGetAllSavedSearches');
    return response;
  },

  agendaSetCurrentSearch({ commit }, search) {
    commit('agendaSetCurrentSearch', search);
  },

  agendaResetCurrentSearch({ commit }) {
    commit('agendaResetCurrentSearch');
  },

  agendaSetRecentSearches({ commit }, searches) {
    commit('agendaSetRecentSearches', searches);
  },

  // eslint-disable-next-line no-unused-vars
  async agendaExportToExcel({ commit, state }, data) {
    const downloadable = await agendaSearchesApi.exportExcel(data);
    return downloadable;
  },
};

// mutations
const mutations = {
  agendaSetCurrentSearch(state, search) {
    state.agendaCurrentSearch = search;
  },
  agendaSetSavedSearches(state, searches) {
    // Reverse order of array because we want to draw the latest item on top.
    searches.data.reverse();
    state.agendaAllSavedSearches = searches;
  },
  agendaAddSavedSearch(state, search) {
    state.agendaAllSavedSearches.data.unshift(search);
  },
  agendaSetRecentSearches(state, searches) {
    state.agendaAllRecentSearches = searches;
  },
  agendaMaxNumberOfSearches(state, payload) {
    state.agendaMaxNumberOfSearches = payload;
  },
  agendaAddRecentSearch(state, search) {
    // Context and document types are sorted to avoid duplicates
    // when we are creating hashes in the future to detect if a search is
    // really new
    const recentSearch = search;

    const sortedQueryDetails = {
      query: recentSearch.queryDetails.query,
      filters: recentSearch.queryDetails.filters.sort(),
    };

    recentSearch.queryDetails = sortedQueryDetails;

    // Add to the top
    state.agendaAllRecentSearches.unshift(recentSearch);

    if (state.agendaAllRecentSearches.length >= Config.maxRecentSearches) {
      // Remove last item
      state.agendaAllRecentSearches.splice(-1, 1);
    }
  },
  agendaSyncCurrentSearch(state, { searchParameters }) {
    state.agendaCurrentSearch.queryDetails = searchParameters;
    state.agendaCurrentSearch.queryDetails.query = searchParameters.query;
  },
  agendaResetCurrentSearch(state) {
    state.agendaCurrentSearch = {
      title: null,
      includeInWebcal: false,
      sendUpdatesPerEmail: {
        dailyChanges: false,
      },
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
