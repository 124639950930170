// App specific
import Config from '@/config';
import statisticsAPI from '../api/europarl-api-statistics';

// initial state
const state = {
  europarlStatisticsTrend: null,
  europarlStatisticsStakeholders: [],
};

// getters
const getters = {
  europarlStatisticsTrend: (state) => state.europarlStatisticsTrend,
  europarlStatisticsStakeholders: (state) => state.europarlStatisticsStakeholders,
};

// actions
const actions = {

  async europarlGetStatistics({ commit, rootState }, analyticsCode) {
    let code = analyticsCode;
    // Get the currently set searchparameters
    const { searchParameters } = rootState;

    const responseTrend = await statisticsAPI.getTrend(searchParameters);
    commit('europarlSetTrend', responseTrend.data);

    if (code) {
      localStorage.setItem('analyticsCode', code);
    } else {
      code = localStorage.getItem('analyticsCode') || Config.defaultAnalyticsCode;
    }

    const responseStakeholders = await statisticsAPI
      .getStakeholders(searchParameters, code);

    // Why is this setStakeholders?
    commit('europarlSetStakeholders', responseStakeholders.data.data);
  },
};

// mutations
const mutations = {
  europarlSetTrend(state, trend) {
    state.europarlStatisticsTrend = trend;
  },
  europarlSetStakeholders(state, stakeholders) {
    state.europarlStatisticsStakeholders = stakeholders;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
