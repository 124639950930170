// App specific
import moment from 'moment-timezone';
import agendaApi from '../api/agenda-api';
import router from '../../../router';

// initial state
const state = {
  all: [],
  current: null,
  filters: [],
  query: '',
  startDate: '',
  endDate: '',
};

// getters
const getters = {
  agendaAll: (state) => state.all,
  agendaCurrent: (state) => state.current,
  agendaFilters: (state) => state.filters,
  agendaQuery: (state) => state.query,
  agendaStartDate: (state) => state.startDate,
  agendaEndDate: (state) => state.endDate,
};

// actions
const actions = {

  setAgendaQuery({ commit }, payload) {
    commit('setAgendaQuery', payload);
  },

  setAgendaFilters({ commit }, args) {
    commit('setAgendaFilters', args);
  },

  setAgendaStartDate({ commit }, payload) {
    commit('setAgendaStartDate', payload);
  },

  setAgendaEndDate({ commit }, payload) {
    commit('setAgendaEndDate', payload);
  },

  setAgendaAllSearchParameters(state, searchParameters) {
    this.commit('setAgendaQuery', searchParameters.query);
    this.commit('setAgendaFilters', searchParameters.filters);
  },

  async getAllAgendas({ commit, getters }) {
    const query = getters.agendaQuery;

    const filters = getters.agendaFilters;

    const startDate = getters.agendaStartDate;

    const endDate = getters.agendaEndDate;

    // Reset all errors
    // commit('clearMessages');

    // Set previous results to zero to set the agenda page empty
    commit('setAgendas', []);

    // Add query to recent searches
    commit('agendaAddRecentSearch', {
      queryDetails: {
        query,
        filters,
      },
    }, { root: true });

    // Validate a query has been set.
    if (!query) {
      commit('setMessage', {
        message: 'Geef een onderwerp op',
        type: 'info',
      }, { root: true });

      return;
    }

    if (query) {
      let origin;
      if (router.currentRoute.query.origin) {
        ({ origin } = router.currentRoute.query.origin);
      }
      const response = await agendaApi.getAll(query, filters, startDate, endDate, origin);
      if (response.data.countTotal === 0 && (router.currentRoute.name !== 'dashboard')) {
        // TODO: It occurs that the error intercepting mechanism in interceptors.js is not working properly
        // See POLMONNL-1972 for details.
        this.dispatch('setMessage', {
          message: 'Geen vergaderingen gevonden',
          type: 'info',
        }, { root: true });
      } else {
        const agendas = response.data.data;

        // Sometimes there is an error on the main dashboard page in agendas
        // For certainty we place an if clause before we iterate over the agenda items
        if (agendas.length > 0) {
          agendas.forEach((agenda) => {
            if (agenda.origin === 'scraper') {
              const startTime = new Date(agenda.startTime);

              // Scraper agendas without startTime have startTime set to 00:01 in the collector.
              // Make them display correctly by setting startTime to endTime.
              if (startTime.getMinutes() === 1) {
                startTime.setMinutes(0);
              }
              const startTimeAMS = moment.tz(startTime, 'Europe/Amsterdam');
              const startOfDayAMS = moment(startTimeAMS).startOf('day');
              if (startTimeAMS.isSame(startOfDayAMS) && agenda.endTime) {
                // It would be more logical to remove the startTime altogether, however
                // it turns out this is more practical in our current uses.
                // Subtract an half hour from the start time is more in suitable for
                // events that have 'tot x uur' in their
                agenda.startTime = new Date(new Date(agenda.endTime)
                  .getTime() - (1 * 60 * 60 * 1000)).toISOString();
              }
            }
          });
        }
        commit('setAgendas', agendas);
      }
    }
  },

  async getAgendaById({ commit }, args) {
    const agenda = await agendaApi.getById(args.id, args.query, args.origin);

    // Scraper agendas without startTime have startTime set to 00:01 in the collector,
    // so they can be found with the elasticsearch query.
    // We set startTime to endTime for consistency with the api agendas
    const agendaData = agenda.data.result;
    const fixedStartTime = new Date(agendaData.startTime);
    if (fixedStartTime.getMinutes() === 1) {
      fixedStartTime.setMinutes(0);
      const startTimeAMS = moment.tz(fixedStartTime, 'Europe/Amsterdam');
      const startOfDayAMS = moment(startTimeAMS).startOf('day');
      if ((startTimeAMS.isSame(startOfDayAMS))) {
        agenda.data.result.startTime = agenda.data.result.endTime;
      }
    }

    // Changed from .data to data.result
    commit('setagendaCurrent', agenda.data.result);
    return agenda;
  },

};

// mutations
const mutations = {
  setAgendas(state, agendas) {
    state.all = agendas;
  },
  setagendaCurrent(state, agenda) {
    state.current = agenda;
  },
  setAgendaFilters(state, args) {
    state.filters = args;
  },
  setAgendaQuery(state, query) {
    state.query = query;
  },
  setAgendaStartDate(state, startDate) {
    state.startDate = startDate;
  },
  setAgendaEndDate(state, endDate) {
    state.endDate = endDate;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
