import documentApi from '../api/europarl-api-document';

// initial state
const state = {
  euDocument: null,
  europarlDocumentRelated: null,
};

// getters
const getters = {
  euDocument: (state) => state.euDocument,
  europarlDocumentRelated: (state) => state.europarlDocumentRelated,
};

// actions
const actions = {
  async euGetDocumentById({ commit }, args) {
    // TODO: With the implemetation of the eu-module it might become handy to
    // add a 'shortDomainType' e.g. 'nl' or 'eu'
    const document = await documentApi.getById(args.id, { highlightWord: args.highlightWord });
    commit('europarlSetDocument', document.data);
    return document;
  },

  async europarlGetRelated({ commit }, refs) {
    // TODO: With the implemetation of the eu-module it might become handy to
    // add a 'shortDomainType' e.g. 'nl' or 'eu'
    const related = await documentApi.getRelated(refs);
    commit('europarlSetDocumentRelated', related.data);
    return related;
  },
};

// mutations
const mutations = {
  europarlSetDocument(state, document) {
    state.euDocument = document;
  },

  europarlSetDocumentRelated(state, related) {
    state.europarlDocumentRelated = related;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
