// App specific
import Config from '@/config';
import statisticsAPI from '../../../api/document/document-api-statistics';

// initial state
const state = {
  statisticsTrend: null,
  statisticsStakeholders: [],
  statisticsTrendingTopics: [],
};

// getters
const getters = {
  statisticsTrend: (state) => state.statisticsTrend,
  statisticsStakeholders: (state) => state.statisticsStakeholders,
  statisticsTrendingTopics: (state) => state.statisticsTrendingTopics,
};

// actions
const actions = {
  /**
   * Retreives all statistics
   *
   * @param {any} { state, commit, rootState }
   */
  async getStatistics({ commit, rootState }, stakeholderCode) {
    let code = stakeholderCode;
    // Get the currently set searchparameters
    const { searchParameters } = rootState;

    // Add statictics grouping to use
    searchParameters.trendsGroupingName = 'nlTrendsGrouping';

    const responseTrend = await statisticsAPI.getTrend(searchParameters);
    commit('setTrend', responseTrend.data);

    if (code) {
      localStorage.setItem('stakeholderCode', code);
    } else {
      code = localStorage.getItem('stakeholderCode') || Config.defaultStakeholderCode;
    }

    const responseStakeholders = await statisticsAPI
      .getStakeholders(searchParameters, code);

    commit('setStakeholders', responseStakeholders.data.data);

    const responseTrendingTopics = await statisticsAPI.getTrendingTopics(searchParameters);

    commit('setTrendingTopics', responseTrendingTopics.data.data);
  },

  async clearStatistics({ commit }) {
    commit('clearStatistics');
  },
};

// mutations
const mutations = {
  setTrend(state, trend) {
    state.statisticsTrend = trend;
  },
  setStakeholders(state, stakeholders) {
    state.statisticsStakeholders = stakeholders;
  },
  setTrendingTopics(state, trendingTopics) {
    state.statisticsTrendingTopics = trendingTopics;
  },
  clearStatistics(state) {
    state.statisticsStakeholders = [];
    state.statisticsTrendingTopics = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
