<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="768"
      v-if="supportDialogVisible"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>
          <v-container class="ma-0 pa-0">
            <v-row>
              <v-col class="d-flex mt-n2">
                <div
                  class="d-inline-block align-self-start text-subtitle-1"
                  style="width: 80%"
                >
                  Support Tips
                </div>
                <div
                  class="d-inline-block text-right align-self-start"
                  style="width: 20%"
                >
                  <v-icon
                    class="mt-n2 mr-n2"
                    @click="hideSupportDialog()"
                  >
                    mdi-close
                  </v-icon>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-tabs
                  v-model="tab"
                  show-arrows
                  light
                  background-color="grey lighten-3"
                >
                  <v-tab>
                    Zoektekens
                  </v-tab>
                  <v-tab>
                    Zoekopdracht <v-icon
                      style="padding-left: 2px; padding-top: 2px;"
                      size="18"
                    >
                      mdi-video
                    </v-icon>
                  </v-tab>
                  <v-tab>
                    Agenda instructie <v-icon
                      style="padding-left: 2px; padding-top: 2px;"
                      size="18"
                    >
                      mdi-file
                    </v-icon>
                  </v-tab>
                  <v-tab>
                    FAQs (9)
                  </v-tab>
                  <v-tab>
                    Contact
                  </v-tab>
                </v-tabs>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text
          style="height: 75vh;"
        >
          <v-tabs-items
            v-model="tab"
          >
            <!-- 1 -->
            <v-tab-item>
              <div class="text-container">
                <div class="boolean-search-syntax">
                  <h4 class="boolean-sign">
                    +
                  </h4>
                  <h4 class="boolean-head">
                    Nauwkeurig zoeken. De termen moeten allemaal in een tekst voorkomen
                  </h4>
                  <p>
                    Voorbeeld: studenten + lenen
                  </p>
                </div>
                <div class="boolean-search-syntax">
                  <h4 class="boolean-sign">
                    |
                  </h4>
                  <h4 class="boolean-head">
                    Breed zoeken. De termen mogen individueel of in combinatie met elkaar voorkomen
                  </h4>
                  <p>
                    Voorbeeld: klimaatakkoord | klimaattransitie | klimaatcrisis
                  </p>
                </div>
                <div class="boolean-search-syntax">
                  <h4 class="boolean-sign">
                    "
                  </h4>
                  <h4 class="boolean-head">
                    Specifieke combinatie van twee of meer termen die bij
                    elkaar moeten staan, of verbonden zijn via een verbindingsstreepje
                  </h4>
                  <p>
                    Voorbeeld: "Circulaire economie"<br>Voorbeeld:  "ict-diensten"<br>
                    Vergeet je de aanhalingstekentjes, dan worden de woorden
                    allemaal los opgezocht.
                  </p>
                </div><div class="boolean-search-syntax">
                  <h4 class="boolean-sign">
                    *
                  </h4>
                  <h4 class="boolean-head">
                    Zoek diverse samenstellingen op basis van één zoekterm
                  </h4>
                  <p>
                    Voorbeeld: pensioen*<br>Met dit voorbeeld zoek je op allerlei
                    onderwerpen waar pensioen in voorkomt, o.a.: pensioenen, pensioenakkoord,
                    pensioenfondsen, pensioenpremie, enzovoorts.
                  </p>
                </div>
                <div class="boolean-search-syntax">
                  <h4 class="boolean-sign boolean-small">
                    ( en )
                  </h4>
                  <h4 class="boolean-head">
                    Bepaal welke termen bij elkaar horen
                  </h4>
                  <p>
                    Voorbeeld: (windenergie | windturbines) + (kust | "aan zee")<br>
                    In dit voorbeeld zoek je op documenten waar winderenergie of windturbines
                    in combinatie met kust of aan zee voorkomen.
                  </p>
                </div>
                <div class="boolean-search-syntax">
                  <h4 class="boolean-sign">
                    -
                  </h4>
                  <h4 class="boolean-head">
                    Uitsluiten van zoekresultaten op basis van bepaalde woorden
                  </h4>
                  <p>
                    Het uitsluiten van resultaten op basis van bepaalde woorden
                    kan onbedoeld relevante documenten wegfilteren. Wij raden aan
                    goed te kijken hoe je via de andere zoektekens, de context
                    en documentfilters je zoekopdracht scherper kunt instellen.
                    Mocht je alsnog willen uitsluiten, dan kan dat natuurlijk.<br>
                    Voorbeeld: dieren + -huisdieren<br>In dit voorbeeld zoek je op
                    documenten waar dieren in voorkomen, maar sluit je de documenten
                    waar het gaat over huisdieren uit.
                  </p>
                </div>
              </div>
            </v-tab-item>

            <!-- 2 -->
            <v-tab-item>
              <div class="video-container">
                <iframe
                  width="720"
                  height="405"
                  src="https://www.youtube.com/embed/OvqzlCzOwzM"
                  title="Een Polpo zoekopdracht aanmaken"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                />
              </div>
            </v-tab-item>

            <!-- 3 -->
            <v-tab-item>
              <div class="file-container">
                <object class="instruction-file" data="https://polpo.nl/bestanden/Polpo%20Agenda%20Synchronisatie%20Instructie.pdf" width="100%" height="840px">
                  <p>Kan PDF-bestand niet weergeven. In plaats daarvan <a href="https://polpo.nl/bestanden/Polpo%20Agenda%20Synchronisatie%20Instructie.pdf">downloaden.</a></p>
                </object>
              </div>
            </v-tab-item>

            <!-- 4 -->
            <v-tab-item>
              <div class="text-container">
                <v-expansion-panels class="faq-container">
                  <!-- 4.1 -->
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      1. Hoe sla ik een zoekopdracht op?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div>
                        <p>Stap 1: Voer een zoekterm in</p>
                        <p><i>Bijv. klimaatakkoord</i></p>
                        <p>Stap 2: Kies één of meerdere contexten</p>
                        <p>
                          <i>Bijv. Economische Zaken & Klimaat, Landbouw Natuur &
                            Voedselkwaliteit, Infrastructuur en Waterstaat</i>
                        </p>
                        <p>
                          Stap 3: Klik op het vergrootglas rechts naast de invoerbalk
                          om de zoekopdracht af te vuren. De monitor vult zich meteen.
                          Kijk rechts in je scherm onder kopje ‘niet opgeslagen’
                          en klik op het groene plusje.
                        </p>
                        <p>
                          Stap 4: Voer een titel in voor je zoekopdracht en kies
                          of je een dagelijkse en/of directe update wilt ontvangen.
                        </p>
                        <p>
                          Stap 5: Klik op opslaan. De zoekopdracht staat nu rechts in
                          je scherm onder je opgeslagen zoekopdrachten
                        </p>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <!-- 4.2 -->
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      2. Hoe pas ik een zoekopdracht aan?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div>
                        <p>Stap 1: Kik op de zoekopdracht die je wil veranderen. </p>
                        <p>
                          Stap 2: Klik op de drie bolletjes om de ‘edit-knoppen’ te openen:<br>
                          <img
                            v-if="this.$vuetify.theme.dark"
                            src="../assets/support-savedsearch-dark.png"
                          >
                          <img
                            v-else
                            src="../assets/support-savedsearch.png"
                          >
                        </p>
                        <p>De ‘edit-knoppen’ zijn nu geopend.</p>
                        <p>
                          <i>Edit-knoppen:<br>Rode prullenbak knop &#x2192; verwijder
                            de zoekopdracht<br>Gele potlood knop &#x2192; wijzig de titel en
                            e-mail notificaties<br>Blauwe synchronisatie knop &#x2192; overschrijf
                            de zoekopdracht met nieuwe instellingen</i>
                        </p>
                        <p>
                          Stap 3: Pas je zoekopdracht aan. Je kunt de onderwerpen in de
                          zoekbalk aanpassen of de contexten of documenttype filters van
                          je zoekopdracht veranderen. Blij met je aanpassingen? Overschrijf
                          dan je oude zoekopdracht door op de blauwe synchronisatie
                          knop te klikken.
                        </p>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <!-- 4.3 -->
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      3. Hoe zet ik mijn e-mail notificaties uit?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div>
                        <p>Stap 1: Kik op de zoekopdracht waarvan je de
                          notificaties wil uitzetten. </p>
                        <p>
                          Stap 2: Klik op de drie bolletjes om de ‘edit-knoppen’ te openen:<br>
                          <img
                            v-if="this.$vuetify.theme.dark"
                            src="../assets/support-savedsearch-dark.png"
                          >
                          <img
                            v-else
                            src="../assets/support-savedsearch.png"
                          >
                        </p>
                        <p>De ‘edit-knoppen’ zijn nu geopend.</p>
                        <p>
                          <i>Edit-knoppen:<br>Rode prullenbak knop &#x2192; verwijder
                            de zoekopdracht<br>Gele potlood knop &#x2192; wijzig de titel en
                            e-mail notificaties<br>Blauwe synchronisatie knop &#x2192; overschrijf
                            de zoekopdracht met nieuwe instellingen</i>
                        </p>
                        <p>
                          Stap 3: Klik op de knop met het gele potlood. Nu opent zich
                          een menu met de settings van je zoekopdracht. Vink de
                          e-mail notificaties aan of uit en klik op opslaan.
                        </p>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <!-- 4.4 -->
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      4. Hoe wijzig ik mijn wachtwoord?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div>
                        <p>
                          Stap 1: Klik op je gebruikersnaam en kies ‘uitloggen’:<br>
                          <img
                            v-if="this.$vuetify.theme.dark"
                            src="../assets/support-logout-dark.png"
                          >
                          <img
                            v-else
                            src="../assets/support-logout.png"
                          >
                        </p>
                        <p>Stap 2: Ga naar ‘wachtwoord vergeten’ onderin het loginscherm.</p>
                        <p>Stap 3: Doorloop het proces om een nieuw wachtwoord aan te maken.</p>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <!-- 4.5 -->
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      5. Kan ik bij Polpo ook via zoektekens (boolean operators) zoeken?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div>
                        <p>Ja, dat kan! Bekijk onze uitleg onder ‘Zoektekens’.</p>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <!-- 4.6 -->
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      6. Ik krijg veel meer resultaten dan verwacht, hoe kan dit?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div>
                        <p>
                          Dit kan meerdere oorzaken hebben. Hieronder noemen we twee veel
                          voorkomende oorzaken. Mocht jouw specifieke oorzaak hier niet bij
                          staan, neem dan contact met ons op.
                        </p>
                        <ul>
                          <li>
                            Zoek je op een onderwerp dat erg algemeen is
                            (bijvoorbeeld energie*) dan raden we je aan om je
                            zoekopdracht te verfijnen. Hiermee voorkom je een
                            informatie overload. Kies bijvoorbeeld voor: energie* + woning*
                          </li>
                          <li>
                            Zet specifieke combinaties van woorden die bij
                            elkaar moeten staan, of verbonden zijn via een verbindingsstreepje
                            tussen aanhalingstekentjes (bijv. "Tweede Kamer", "ict-diensten").
                            Wanneer je dit vergeet leidt dit tot veel meer resultaten dan
                            verwacht. De woorden worden dan allemaal los opgezocht.
                          </li>
                        </ul>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <!-- 4.7 -->
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      7. Kan ik ergens de stakeholderbronnen zien die ik volg?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div>
                        <p>
                          Het is niet mogelijk om in het dashboard een overzicht
                          te bekijken van de stakeholderbronnen die je volgt.
                        </p>
                        <p>
                          Wil je dit overzicht zien? Neem dan contact met ons op,
                          wij sturen je graag een overzicht per mail.
                        </p>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <!-- 4.8 -->
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      8. Ik ga weg en wil mijn account overzetten naar mijn opvolger, kan dat?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div>
                        <p>
                          Dat kan. Maar let op: het overzetten van accounts is nog
                          niet volledig geautomatiseerd. Schakel onze hulp hiervoor in.
                          Wij kunnen helpen bij het handmatig overzetten van jouw
                          zoekopdrachten naar het account van jouw opvolger.
                        </p>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <!-- 4.9 -->
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      9. Hoe kan ik jullie bereiken?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div>
                        <p>
                          Heb je vragen over het gebruik van Polpo?<br>
                          Gebruik onze chat of neem contact op met
                          Caspar<br>

                          (<a
                            class="text--secondary"
                            style="text-decoration: none !important;"
                            href="mailto:cpille@polpo.nl"
                          >cpille@polpo.nl</a>,
                          <a
                            class="text--secondary"
                            style="text-decoration: none !important;"
                            href="tel:0641571921"
                          >+ 31 6 41 57 19 21</a>)
                        </p>
                        <p>
                          Heb je vragen over je Polpo abonnement?<br>
                          Neem contact op met Johan<br>
                          (<a
                            class="text--secondary"
                            style="text-decoration: none !important;"
                            href="mailto:jvanzaanen@polpo.nl"
                          >jvanzaanen@polpo.nl</a>,
                          <a
                            class="text--secondary"
                            style="text-decoration: none !important;"
                            href="tel:0618854417"
                          >+ 31 6 18 85 44 17</a>)
                        </p>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-tab-item>

            <!-- 5 -->
            <v-tab-item>
              <div class="text-container">
                <div class="support-contact">
                  <p>Info:</p>
                  <p>
                    Heb je vragen over het gebruik van Polpo?<br>Gebruik onze
                    chat of neem contact op met Caspar<br>
                    (<a
                      class="text--secondary"
                      style="text-decoration: none !important;"
                      href="mailto:cpille@polpo.nl"
                    >cpille@polpo.nl</a>,
                    <a
                      class="text--secondary"
                      style="text-decoration: none !important;"
                      href="tel:0641571921"
                    >+ 31 6 41 57 19 21</a>)
                  </p>
                  <p>
                    Heb je vragen over je Polpo abonnement?<br>Neem contact op met Johan<br>
                    (<a
                      class="text--secondary"
                      style="text-decoration: none !important;"
                      href="mailto:jvanzaanen@polpo.nl"
                    >jvanzaanen@polpo.nl</a>,
                    <a
                      class="text--secondary"
                      style="text-decoration: none !important;"
                      href="tel:0618854417"
                    >+ 31 6 18 85 44 17</a>)
                  </p>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      dialog: true,
      tabPosition: 'right',
      tab: null,
    };
  },

  computed: {
    ...mapGetters({
      supportDialogVisible: 'supportDialogVisible',
    }),
  },

  methods: {
    hideSupportDialog() {
      this.$store.dispatch('setSupportDialogVisible', false);
    },
  },
};
</script>

<style lang="scss" scoped>
  .text-container {
    display: block;
    margin-top: 24px;
  }
  .v-tab {
      letter-spacing: .0em !important;
      text-transform: none !important;
  }
  .boolean-search-syntax {
    h4 {
      &.boolean-head {
        padding-left: 50px;
        margin-top: -26px;
      }
      &.boolean-sign {
        display: inline-block;
        font-size: 120%;
        &.boolean-small {
          font-size: 100%;
        }
      }
    }
    p {
      padding-left: 50px;
      margin-bottom: 15px;
    }
  }

  .video-container {
    display: block;
    margin-top: 24px;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .file-container {
    margin-top: 24px;
  }

  .faq-container {
    p img {
      padding-top: 10px;
    }
  }
</style>
